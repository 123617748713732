import { axiosInstance } from 'config/axios'

export function login({ email, password }: LoginParams): Promise<any> {
  return axiosInstance.post(`/auth/login`, {
    email,
    password,
  })
}

export interface LoginParams {
  email: string
  password: string
}

export function refreshToken(refreshToken: string): Promise<any> {
  return axiosInstance.post(`/auth/refresh`, {
    refreshToken,
  })
}

export function sendOtpEmail({ email }: { email: string }): Promise<any> {
  return axiosInstance.post(`/auth/forgot_password/sendOtpEmail`, {
    email,
  })
}

export function verifyOtpEmail({ email, otpCode }: { email: string; otpCode: string }): Promise<any> {
  return axiosInstance.post(`/auth/forgot_password/verifyOtpEmail`, {
    email,
    otpCode,
  })
}

export function resetPassword({ email, password }: { email: string; password: string }): Promise<any> {
  return axiosInstance.post(`/auth/forgot_password/resetPassword`, {
    email,
    password,
  })
}
