import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import 'index.scss'
const App = lazy(() => import('App'))

import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import reportWebVitals from 'reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'states/store'
import { queryClient } from './config/react-query'
import { QueryClientProvider } from 'react-query'
import { ApolloProvider } from '@apollo/client'
import { client } from './config/apollo'
import { SnackbarProvider } from 'notistack'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
  Filler,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Title,
  Legend,
  Filler,
  // ChartDataLabels,
  Tooltip,
)

const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: 'black',
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span className="loader"></span>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <Suspense fallback={<Loading />}>
              <App />
            </Suspense>
          </SnackbarProvider>
        </Provider>
      </QueryClientProvider>
    </ApolloProvider>
  </BrowserRouter>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
