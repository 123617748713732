import thunk from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import testReducer from './slice/test'
import modalDialogReducer from './slice/modalDialog'
import loadingReducer from './slice/loading'
import towerTypeReducer from './slice/towerType'
import userInfoReducer from './slice/userInfo'

export const store = configureStore({
  reducer: {
    test: testReducer,
    modalDialog: modalDialogReducer,
    loading: loadingReducer,
    towerType: towerTypeReducer,
    userInfo: userInfoReducer,
  },
  middleware: [thunk],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
