import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IUserInfo {
  email: string
  userRole: string
  exp: number
  iat: number
  status: string
  fullName: string
}

const initialState: IUserInfo = {
  email: '',
  userRole: '',
  exp: 0,
  iat: 0,
  status: 'true',
  fullName: '',
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IUserInfo>) => {
      state.email = action.payload.email
      state.userRole = action.payload.userRole
      state.exp = action.payload.exp
      state.iat = action.payload.iat
      state.status = action.payload.status
      state.fullName = action.payload.fullName
    },
  },
})

export const { setUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer
