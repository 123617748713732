import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TowerType } from 'utils/generated'

interface towerTypeState {
  towerTypeList: []
  towerTypeListOption: { value: string; label: string }[]
  refetch: () => void
}

const initialState: towerTypeState = {
  towerTypeList: [],
  towerTypeListOption: [],
  refetch: () => null,
}

export const towerTypeSlice = createSlice({
  name: 'towerType',
  initialState,
  reducers: {
    setTowerType: (state, action: PayloadAction<any>) => {
      state.towerTypeList = action.payload.items
      state.towerTypeListOption = action.payload.items.map((data: TowerType) => ({
        value: data.towerTypeID,
        label: data.towerName,
      }))
      state.refetch = action.payload.refetch
    },
  },
})

export const { setTowerType } = towerTypeSlice.actions

export default towerTypeSlice.reducer
