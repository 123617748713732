import { createSlice } from '@reduxjs/toolkit'

interface LoadingState {
  isOpen: boolean
}

const initialState: LoadingState = {
  isOpen: false,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setOpenLoading: (state) => {
      state.isOpen = true
    },
    setCloseLoading: (state) => {
      state.isOpen = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCloseLoading, setOpenLoading } = loadingSlice.actions

export default loadingSlice.reducer
